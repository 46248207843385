#navcontainer {
    width: 100%;
    height: 84px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    font-size: 17px;
    font-family: Roboto;
    letter-spacing: 1.2px;
    position: absolute;
  }

  .web3modal-modal-lightbox {
    z-index: 5000 !important;
  }

  .selected {
      font-family: Roboto;
      font-weight: 700;
      border-bottom: 3px solid black;
  }

  

  #navlogo {
      width: 250px;
      min-height: 30px;
      background-image: url('../../files/logo.png');
      background-size: cover;
      margin-right: 20px;
      margin-left: 60px;
  }

  #navline {
      height: 50px;
      width: 1px;
      background-color: gray;
      margin-right: 20px;
  }

.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 27px;
    top: 20px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #555868;
  }
  
  /* Position and sizing of clickable cross button */

.bm-cross-button {
    top: 20px !important;
    right: 27px !important;
    height: 39px !important;
    width: 36px !important;
  }

/* This targets the actual elements that make up the cross */
.bm-cross {
    width: 5px !important;
    height: 30px !important;
}
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    top: 0;
    height: 100%;
    display: block !important;
    width: 100vw !important;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #F2F2F2;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: black;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    background-color: #F2F2F2;
    display: flex;
    justify-content: center;    
  }
  
  /* Individual item */
  .bm-item {
    outline: none;
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  #navulright {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    align-items: center;
    padding: 0px;
    margin-bottom: 20px;
  }

  #navulleft {
    display: flex;
    list-style-type: none;
    justify-content: center;
    align-items: flex-end;
    padding: 0px;
    font-size: 22px;
    margin-bottom: 25px;
  }



  .navli {
    line-height: 180%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    margin-right: 25px;
  }

  .walletbutton {
    box-shadow: 10px 5px 10px 0px #7e7e7e54, -4px -4px 15px 0px rgb(255, 255, 255);
    transition: 1s ease-in-out;
    background-color: #F2F2F2;
    border-radius: 40px;
    padding: 5px 30px;
    margin-right: 15px;
    cursor: pointer;
  }

  .walletbutton:hover {
    box-shadow: 4px 4px 15px 0px #ffffff, -8px -5px 10px 0px #7e7e7e54;
    background-color: #E9E9E9;
  }

  .opensea-button-nav {
    width: 28px;
    height: 40.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
    cursor: pointer;
  }

  .os-logo-nav {
    background-image: url('../../files/opensea.png');
    background-size: cover;
    width: 28px;
    height: 28px;
}

@media only screen and (max-width: 1164px) {
  #navulright.bm-item {
    font-size: 22px;
    margin-top: 80px;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    }

  .navli {
    margin-bottom: 10px;
    margin-top: 5px;
    margin-right: 0px;
  }

  .walletbutton {
    font-size: 20px;
    margin-right: 0px;
  }

  #mobiledisc {
    margin-right: 25px;
  }
    
  #mobiletwit {
    margin-right: 25px;
  }

  #navlogo {
    margin: 0px;
}
.opensea-button-nav {
  margin-right: 0px;
}

  #navsocials {
      width: 220px;
      display: flex;
      justify-content: space-between;
  }
}


#burnbutton {
  background-color: gray;
  color: black;
  width: 300px;
  height: 100px;
  font-size: 22px;
}