body, html {
    margin: 0;
    padding: 0;
    background-color: #EEEEEE;
    font-family: Roboto, sans-serif;
}

a {
    text-decoration: none;
    color: black;
}
#maincontainer {
    position: relative;
    overflow: hidden;
}

  #fallbackcontainer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100vh;
      width: 100vw;
      min-width: 300px;
      min-height: 300px;
      background-image: linear-gradient(to top, rgb(24, 24, 24), rgba(61, 61, 61, 1));
  }

  #fallbacktext {
      color: white;
      font-family: roboto;
      font-size: 55px;
      text-align: center;
  }

  #fallbackbutton {
      font-size: 20px;
        display: flex;
        justify-content: center;
        color: white;
        align-items: center;
        background-color: black;
        width: 220px;
        height: 55px;
        border-radius: 6px;
        font-family: roboto;
        margin-top: 40px;
        transition: ease-in-out .5s;
  }

  #fallbackbutton:hover {
      box-shadow: 0px 0px 10px 1px #ffffff;
      background-color: #000000;
  }

  #placeholdercontainer {
    width: 100vw;
    height: calc(100vh - 84px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: roboto;
    background-color: #F5F5F5;
  }
  
  #placeholderbutton {
    padding: 13px 20px;
    font-size: 20px;
    background-color: #C7F9F9;
    transition: 1s ease-in-out;
    border-radius: 12px;
    cursor: pointer;
  }
  
  #phtitle {
    color: black;
    font-size: 50px;
    font-family: 700;
    text-align: center;
  }
  
  #phtitle2 {
    margin-top: 20px;
    margin-bottom: 30px;
    color: #D0AE54;
    font-size: 22px;
    text-align: center;
  
  }
  
  
  
  #placeholderbutton:hover {
    box-shadow: 0px 0px 10px 0px #4d4d4d;
  }