.how-to-card {
    background-color: #202020;
    width: 350px;
    height: 500px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
    border-radius: 15px;
    text-align: center;
    position: relative;
    font-size: 18px;
}


#how-to-container {
    padding-top: 60px;
    padding-bottom: 60px;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    background-image: url('../../files/roadmapbg.png');
}

#mm-logo {
    position: absolute;
    width: 130px;
    bottom: -60px;
}

#eth-logo {
    position: absolute;
    width: 120px;
    bottom: -50px;
}

#wlogo {
    position: absolute;
    width: 220px;
    bottom: -12px;
}

#t1-title {
    letter-spacing: 1px;
    font-size: 24px;
    color: #F6851B;
    margin-top: 70px;
    font-weight: 500;
    width: 60%;
    text-align: center;

}

#t1-text {
    margin-top: 80px;
    width: 75%;
}

#t2-title {
    letter-spacing: 1px;
    font-size: 24px;
    margin-top: 70px;
    color: rgb(175, 175, 175);
    font-weight: 500;
    width: 60%;
    text-align: center;

}

#t2-text-1 {
    margin-top: 40px;
    width: 75%;
}

#t2-text-2 {
    margin-top: 40px;
    width: 75%;
}

#t3-title {
    letter-spacing: 1px;
    font-size: 24px;
    margin-top: 70px;
    font-weight: 500;
    width: 60%;
    text-align: center;

}

#t3-text {
    margin-top: 80px;
    width: 75%;
}

#f-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}