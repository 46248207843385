#e-bg {
    width: 100vw;
    min-height: calc(100vh - 117px);
    background-image: url('../../files/exchange-bg.png');
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
}

#e-text-1 {
    font-weight: 600;
    font-size: 30px;
    margin-top: 50px;
    margin-bottom: 40px;
    text-align: center;
}

#e-text-2 {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 22px;
}

.e-arrow {
    font-size: 30px;
    margin: 20px 0px;
}

#e-message {
    font-size: 22px;
    text-align: center;
    width: 100%;
}

#e-watch-container {
    width: 90%;
    max-width: 1000px;
    background-color: white;
    overflow-x: scroll;
    display: flex;
    height: 320px;
    border-radius: 27px;
    align-items: center;
    box-shadow: 10px 5px 10px 0px #7e7e7e54, -4px -4px 15px 0px rgb(255, 255, 255);
}

#e-output {
    width: 200px;
    background-color: white;
    overflow-x: scroll;
    display: flex;
    height: 55px;
    border-radius: 16px;
    justify-content: center;
    font-weight: 500;
    font-size: 20px;
    align-items: center;
    box-shadow: 10px 5px 10px 0px #7e7e7e54, -4px -4px 15px 0px rgb(255, 255, 255);
}


.e-watch-img {
   width: 230px;
    margin-bottom: 13px;
    border-top-left-radius: 17px;
    border-top-right-radius: 17px;
}

.exchange-watch-tile {
    cursor: pointer;
    background-color: #ececec;
    border-radius: 20px;
    width: 230px !important;
    height: 280px;
    margin: 0px 20px;
    display: flex;
    flex-direction: column;
    padding: 3px;
    align-items: center;
}

#burn-btn {
    cursor: pointer;
    width: 320px;
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 90px;
    border-radius: 15px;
    height: 60px;
}

.e-selected {
    padding: 0px;
    border: 3px solid greenyellow;
}