.claim-input {
    margin-top: 20px;
    height: 50px;
    width: 90px;
    text-align: center;
    font-size: 20px;
}

.email-input {
    margin-top: 15px;
    height: 28px;
    width: 250px;
    font-size: 17px;
    border-radius: 5px;
    margin-bottom: 5px;
    border: 2px solid black;
    padding-left: 5px;
}


#opaque-claim-btn {
    cursor: pointer;
    width: 320px;
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 90px;
    border-radius: 15px;
    height: 60px;
    opacity: .5;
}

#c-error {
    font-size: 14px;
    color: red;
}

#c-confirmation {
    font-size: 14px;
}

#c-email {
    width: 90%;
    max-width: 600px;
    font-size: 18px;
    margin-bottom: 10px;
    text-align: center;
}

#c-email-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 40px 10px;
    border-radius: 28px;
    box-shadow: 10px 5px 10px 0px rgb(126 126 126 / 33%), -4px -4px 15px 0px white;
}