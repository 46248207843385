#s-gold {
    width: 100vw;
    height: 33px;
    background-color: #E2B85B;
}

#s-gray {
    width: 100vw;
    height: 84px;
    background-color: #f1f1f1;
}

#s-bg {
    width: 100vw;
    min-height: calc(100vh - 117px);
    background-image: url('../../files/roadmapbg.png');
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

#s-top {
    display: flex;
    align-items: center;
    margin-top: 80px;
    margin-bottom: 70px;
}

#s-top-text {
    line-height: 131%;
    width: 450px;
    font-size: 22px;
    margin-right: 60px;
}

.story-watch {
    width: 240px;
}

#s-text-box {
    padding: 60px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    width: 80%;
    max-width: 800px;
    background-color: #262627;
    margin-bottom: 120px;
    box-shadow: -2px -2px 3px 0px rgba(88, 88, 88, 0.74) inset, 2px 2px 5px 0px rgba(0, 0, 0, 0.568) inset;
}

#s-box-top {
    margin-bottom: 20px;
    text-align: center;
    width: 80%;
}
#s-box-bottom {
    text-align: center;
    width: 80%;
}

#s-footer-text {
    width: 80%;
    text-align: center;
}
#s-footer-socials {
    margin-top: 40px;
    margin-bottom: 60px;
    width: 200px;
    font-size: 24px;
    justify-content: space-between;
    display: flex;
}

@media only screen and (max-width: 863px) {
    #s-top {
        text-align: center;
        flex-direction: column;
    }

    #s-top-text {
        margin-right: 0px;
    }

    #s-gold {
        height: 69px;
    }
    
}

@media only screen and (max-width: 568px) {
    #s-top {
        text-align: center;
        flex-direction: column;
    }

    #s-top-text {
        margin-right: 0px;
        width: 85%;
    }
}
