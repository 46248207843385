#gallery-container {
    background-color: #f5f5f5;
    width: 100vw;
    min-height: calc(100vh - 84px);
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding-top: 84px;
}

#my-gallery-container {
    background-color: #f5f5f5;
    width: 100vw;
    min-height: calc(100vh - 84px);
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding-top: 140px;
}

#mynftmessage {
    text-align: center;
    font-size: 24px;
    align-self: flex-start;
    margin-top: 100px;
  }

#filter-bar {
    width: calc(100vw - 100px);
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    margin-top: 20px;
}

#paging {
    width: calc(100vw - 100px);
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.pagebutton {
    background-color: black;
    color: #E2dcdc;
    padding: 12px 30px;
    border-radius: 5px;
    cursor: pointer;
}

#ascendbutton {
    margin-left: 30px;
}

#sorttext {
    margin-right: 20px;
}

.mynfts {
    padding-top: 100px;
}

@media only screen and (max-width: 600px) {
    #filter-bar {
        flex-direction: column;
    }

    #ascendbutton {
        margin-left: 0px;
        margin-top: 20px;
    }
    
    #sorttext {
        margin-right: 0px;
        margin-bottom: 20px;
    }
}