#roadmapcontainer {
    background-image: url('../../files/roadmapbg.png');
    background-size: cover;
    width: 100vw;
    min-height: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Roboto, sans-serif;
}

#rm-clock {
    width: 500px;
}

#clock-relative {
    position: relative;
    margin-top: 200px;
    margin-bottom: 80px;
}

.phase-title {
    color: #A3F3F4;
    font-size: 21px;
}

.phase-time {
    color: white;
    font-size: 17px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.phase-text {
    color: gray;
}

.clockphase {
    max-width: 210px;
    position: absolute;
}

#phase1 {
    top: -70px;
    right: -112px;
}

#phase0 {
    top: -100px;
    left: 50%;
    transform: translateX(-50%)
}

#phase2 {
    top: 50px;
    right: -215px;
}

#phase3 {
    top: 170px;
    right: -250px;
}

#phase4 {
    top: 339px;
    right: -238px;
}

#phase5 {
    bottom: -165px;
    right: -15px;
}

#phase6 {
    bottom: -145px;
    left: 54%;
    transform: translateX(-50%)
}

#phase7 {
    top: 490px;
    left: -40px;
}

#phase8 {
    top: 360px;
    left: -140px;
}

#phase9 {
    top: 200px;
    left: -210px;
}

#phase10 {
    top: 80px;
    left: -80px;
}

#phase11 {
    top: -20px;
    left: 10px;
}

#r-title {
    color: white;
    position: absolute;
    font-size: 40px;
    width: 654px;
    top: -230px;
    left: 50%;
    transform: translateX(-50%);
}

@media only screen and (max-width: 999px) {
    #roadmapcontainer {
        min-height: 2150px;
    }

    #rm-vert-clock {
        width: 70px;
    }

    #mobile-rm-container {
        display: flex;
        margin-top: 50px;
        width: 45%;
        justify-content: space-between;
        position: relative;
    }

    #mobile-rm-text {
        display: flex;
        flex-direction: column;
    }

    .clockphase {
        position: static;
    }

    #phase1-m {
        margin-bottom: 60px;
    }
    
    #phase0-m {
        margin-bottom: 80px;
        margin-top: -20px;
    }
    
    #phase2-m {
        margin-bottom: 22px;
    }
    
    #phase3-m {
        margin-bottom: 20px;
    }
    
    #phase4-m {
        margin-bottom: 10px;
    }
    
    #phase5-m {
        margin-bottom: 20px;
    }
    
    #phase6-m {
        margin-bottom: 30px;
    }
    
    #phase7-m {
        margin-bottom: 30px;
    }
    
    #phase8-m {
        margin-bottom: 40px;
    }
    
    #phase9-m {
        margin-bottom: 60px;
    }

    #phase11-m {
        position: absolute;
        bottom: -20px;
    }

    #r-m-title {
        position: absolute;
        color: white;
        left: 50%;
        transform: translateX(-50%);
        width: 80vw;
        top: -100px;
        font-size: 24px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (max-width: 800px) {
    #mobile-rm-container {
        width: 65%;
    }
}

@media only screen and (max-width: 500px) {
    #mobile-rm-container {
        width: 80%;
    }
}


@media only screen and (min-width: 1300px) {
    #phase1 {
        top: -92px;
        right: -125px;
    }
    
    #phase0 {
        top: -130px;
        left: 50%;
        transform: translateX(-50%)
    }
    
    #phase2 {
        top: 30px;
        right: -230px;
    }
    
    #phase3 {
        top: 170px;
        right: -290px;
    }
    
    #phase4 {
        top: 359px;
        right: -268px;
    }
    
    #phase5 {
        bottom: -160px;
        right: -32px;
    }
    
    #phase6 {
        bottom: -165px;
        left: 54%;
        transform: translateX(-50%)
    }
    
    #phase7 {
        top: 500px;
        left: -60px;
    }
    
    #phase8 {
        top: 360px;
        left: -170px;
    }
    
    #phase9 {
        top: 200px;
        left: -250px;
    }
    
    #phase10 {
        top: 60px;
        left: -100px;
    }
    
    #phase11 {
        top: -49px;
        left: -0px;
    }
}