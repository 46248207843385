#tos-container {
    min-height: calc(100vh - 100px);
    background-color: #F5F5F5;
    width: 100vw;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.toslist {
    width: 85%;
    max-width: 700px;
    margin-top: 25px;
    line-height: 130%;
}

#tos-title {
    margin-top: 50px;
    font-size: 35px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 863px) {
    #tos-container {
        min-height: 100vh;
    }
}
