$bg: #F2F2F2;

#section3container {
    width: 100vw;
    background-image: url('../../files/s3bg.png');
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Roboto, sans-serif;
}

#s3-top-text {
    font-weight: 500;
    margin-top: 100px;
    margin-bottom: 150px;
    width: 80%;
    max-width: 780px;
    font-size: 30px;
    text-align: center;
}

.s3-containers-left {
    width: 80%;
    max-width: 1100px;
    display: flex;
    align-items: center;
    background-color: $bg;
    box-shadow: 10px 5px 10px 0px #7e7e7e41, -4px -4px 15px 0px rgb(255, 255, 255);
    border-radius: 83px;
    position: relative;
    margin-bottom: 180px;
}

.s3-containers-right {
    width: 80%;
    max-width: 1100px;
    display: flex;
    align-items: center;
    background-color: $bg;
    box-shadow: 10px 5px 10px 0px #7e7e7e41, -4px -4px 15px 0px rgb(255, 255, 255);
    border-radius: 83px;
    position: relative;
    margin-bottom: 180px;
}

.s3-watch-left {
    width: 220px;
    position: absolute;
    left: 115px;
}

.s3-watch-name-left {
    transform: rotate(270deg);
    font-weight: 500;
    margin: 20px;
    margin-right: 240px;
    font-size: 25px;
}

.s3-watch-descrip-right {
    margin-right: 40px;
    margin-top: 28px;
    margin-bottom: 28px;
    font-size: 19px;
}

.s3-watch-right {
    width: 220px;
    position: absolute;
    right: 100px;
}

.s3-watch-name-right {
    transform: rotate(270deg);
    font-weight: 500;
    margin: 20px;
    margin-left: 240px;
    font-size: 25px;
}

.s3-watch-descrip-left {
    margin-left: 40px;
    margin-top: 28px;
    margin-bottom: 28px;
    font-size: 19px;
    text-align: right;
}

.s3-bold {
    font-weight: 500;
}

#s3-line {
    width: 70%;
    margin-bottom: 30px;
    margin-top: 30px;
    height: 4px;
    background-image: radial-gradient(50% 50% at 50% 50%, #FFF2BF 0%, #EBC985 23.44%, #E2B85B 41.15%, #DDA632 66.15%, rgba(208, 174, 84, 0.5) 84.38%, rgba(196, 196, 196, 0) 100%);
}

#footer-text {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
    
}

#footer-socials {
    display: flex;
    justify-content: space-between;
    width: 250px;
}

#layerr-branding {
    display: flex;
    margin-top: 30px;
    align-items: center;
}

#layerr-txt {
    font-size: 18px;
    margin-right: 12px;
}

#layerrlogo {
    background-image: url('../../files/layerrlogo.png');
    width: 100px;
    height: 27px;
    background-size: cover;
}

#burn-table {
    width: 80%;
    max-width: 1100px;
    background-color: #262627;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    margin-bottom: 50px;
}

#table-header {
    width: 70%;
    height: 12%;
    padding: 0px 80px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    height: 40px;
    margin-top: 20px;
}

.table-row {
    margin-bottom: 20px;
    margin-top: 20px;
    align-items: center;
    border-radius: 10px;
    width: 70%;
    height: 60px;
    padding: 0px 80px;
    justify-content: space-between;
    box-shadow: -2px -2px 5px 0px rgba(117, 117, 117, 0.74) inset, 2px 2px 5px 0px rgba(0, 0, 0, 0.568) inset;
    display: flex;
}

.row-l {
    width: 100px;
    display: flex;
    justify-content: center;
    align-content: center;
}

.row-r {
    width: 100px;
    display: flex;
    justify-content: center;
    align-content: center;
}



@media only screen and (max-width: 1120px) {
    .s3-containers-right {
        border-radius: 40px;
    }

    .s3-containers-left {
        border-radius: 40px;
    }

    .s3-watch-name {
        margin: 10px;
        margin-right: 235px;
    }

    .s3-watch-left {
        left: 105px;
    }
}


@media only screen and (max-width: 855px) {
    .s3-containers-left {
        width: 55%;
        margin-left: 35%;
        margin-bottom: 140px;
    }

    #table-header {
        padding: 0px 40px;
    }
    
    .table-row {
        padding: 0px 40px;
    }

    .s3-containers-right {
        width: 55%;
        margin-right: 35%;
        margin-bottom: 140px;
    }

    .s3-watch-name-left {
        margin: .1px;
        font-size: 22px;
        position: absolute;
        transform: rotate(0deg);
        top: -40px;
        left: 40%;
    }

    .s3-watch-left {
        left: -200px;
        width: 180px;
    }

    .s3-watch-descrip-right {
        margin-right: 10px;
        margin-left: 10px;
        margin-bottom: 20px;
        margin-top: 20px;
        font-size: 14pt;
        text-align: center;
    }

    .s3-watch-name-right {
        margin: .1px;
        font-size: 22px;
        position: absolute;
        transform: rotate(0deg);
        top: -40px;
        right: 40%;
    }

    .s3-watch-right {
        right: -200px;
        width: 180px;
    }

    .s3-watch-descrip-left {
        margin-right: 10px;
        margin-left: 10px;
        margin-bottom: 20px;
        margin-top: 20px;
        font-size: 14pt;
        text-align: center;
    }
    
}

@media only screen and (max-width: 500px) {
    .s3-containers-left {
        width: 65%;
        margin-left: 25%;
    }
    .s3-containers-right {
        width: 65%;
        margin-right: 25%;
    }

    #table-header {
        padding: 0px 20px;
    }
    
    .table-row {
        padding: 0px 20px;
    }

    .s3-watch-name-left {
        margin: .1px;
        font-size: 22px;
        position: absolute;
        transform: rotate(0deg);
        top: -40px;
        left: 33%;
    }

    .s3-watch-left {
        left: -120px;
        width: 133px;
    }

    .s3-watch-descrip-right {
        margin-right: 10px;
        margin-left: 10px;
        margin-bottom: 20px;
        margin-top: 20px;
        font-size: 12pt;
        text-align: center;
    }

    .s3-watch-name-right {
        margin: .1px;
        font-size: 22px;
        position: absolute;
        transform: rotate(0deg);
        top: -40px;
        right: 35%;
    }

    .s3-watch-right {
        right: -120px;
        width: 133px;
    }

    .s3-watch-descrip-left {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px;
        margin-top: 20px;
        font-size: 12pt;
        text-align: center;
    }
    
}


