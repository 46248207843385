#watch-details-container {
    width: 100vw;
    min-height: 100vh;
    // height: 1200px;
    background-image: url('../../files/s3bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 84px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

#w-d-tiles {
    // top: 200px;
    // left: 50%;
    // transform: translateX(calc(-50% + 300px));
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 90%;
    max-width: 550px;
    flex-wrap: wrap;
    position: relative;
    margin-top: 100px;
    margin-bottom: 50px;
}

.w-d-tile {
    background-color: white;
    border-radius: 20px;
    margin: 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 220px;
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out;
}

.w-d-tile:hover {
    box-shadow: 0px 2px 5px 0px rgba(85, 85, 85, 0.420);
}

.w-d-title {
    font-size: 22px;
    margin-top: 20px;
    font-weight: 500;
    margin-bottom: 5px;
}

.w-d-part {
    margin-bottom: 20px;
}

.material-container {
    display: flex;
    width: 550px;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
}

.watch-material {
    width: 240px;
    border-bottom-left-radius: 18px;
    margin-right: 20px;
}

.inactive-tile {
    display: none;
}

.active-tile {
    display: flex !important;
}

#watch-image {
    width: clamp(500px, 30%, 630px);
    border-radius: 40px;
    margin-right: 50px;
    margin-top: 150px;
}

.material-text {
    margin-right: 25px;
}

@media screen and (max-width: 1090px) {
    #watch-image {
        position: relative;
        transform: none;
        top:auto;
        left: auto;
        width: 90%;
        max-width: 500px;
        margin-right: 0px;
        margin-top: 30px;
    }

    #w-d-tiles {
        margin-left: 0;
        margin-top: 80px;
        margin-bottom: 100px;
    }

    #watch-details-container {
        flex-direction: column;
        align-items: center;
    }


}

@media screen and (max-width: 609px) {

    .w-d-tile {
        width: 90%;
        max-width: 550px;
    }

    .material-container {
        flex-direction: column-reverse;
        width: 100%;
        text-align: center;
    }

    .material-text {
        width: 85%;
        margin-right: 0px;
    }

    .active-tile {
        width: 100%;
    }

    .watch-material {
        width: 100%;
        border-bottom-right-radius: 18px;
        margin-right: 0;
        margin-top: 30px;
    }
    
}
    