#section1container {
    background-image: url('../../files/bg.png');
    min-height: calc(100vh - 130px);
    background-size: cover;
    background-position: top;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: roboto, sans-serif;
}

#whitewatch {
    max-width: 500px;
    width: 80%;
    margin-top: 100px;
}

#s1-logo {
    background-image: url('../../files/logo.png');
    background-size: cover;
    width: 321px;
    height: 39px;
    margin-top: 40px;
    margin-bottom: 20px;
}

#s1-title-2 {
    color: #D0AE54;
    font-size: 30px;
    text-align: center;
}

#s1-title-3 {
    font-size: 22px;
    text-align: center;
    font-weight: 500;
}


#double-line {
    font-size: 22px;
    text-align: center;
    font-weight: 500;
    margin-right: 10px;
    margin-left: 10px;
}
#datesbox {
    display: flex;
    margin-top: 20px;
}

#s1-title-4 {
    font-size: 22px;
    text-align: center;
    font-weight: 500;
}

#s1-text-1 {
    width: 90%;
    max-width: 1000px;
    text-align: center;
    margin-top: 50px;
    font-size: 19px;
}

#s1-text-2 {
    width: 90%;
    max-width: 1000px;
    text-align: center;
    margin-top: 20px;
    font-size: 19px;
}

#placeholder-box {
    width: 1000px;
    height: 400px;
    background-color: #262627;
    color: white;
    border-radius: 30px;
    display: flex;
    margin-top: 50px;
}

#s1-gif {
    width: 450px;
    height: 400px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

#placeholder-text {
    width: 650px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 18px;
}

#p-text-1 {
    width: 75%;
    padding: 30px 35px;
    border-radius: 30px;
    box-shadow: 0 0 10px 0px #000 inset;
    text-align: center;
    background-color: #323233;
}

#p-title {
    font-weight: 500;
    font-size: 21px;
    margin-top: 20px;
}

#p-text-2 {
    width: 75%;
    text-align: center;
}

#s1-transparent-box {
    max-width: 1000px;
    width: 80%;
    background-color: rgba(255, 255, 255, 0.733);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    min-height: 120px;
    border-radius: 15px;
    margin-bottom: 40px;
    box-shadow: 0px 4px 11px 0px rgba(116, 116, 116, 0.459);
    white-space: pre-line;
}

#s1-transparent-text {
    opacity: 1;
    width: 80%;
    max-width: 700px;
    text-align: center;
    font-size: 19px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
}

.socialicon {
    background-image: url('../../files/socialbg.png');
    background-size: cover;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
}

.socialinner {
    background-color: white;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
}

#topsocials {
    display: flex;
    width: 80px;
    height: 290px;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 380px;
    left: 7vw;
    font-size: 18px;
}


@media only screen and (max-width: 820px) {
    #topsocials {
        display: none;
    }

  }


@media only screen and (max-width: 1090px) {


    #placeholder-box {
    flex-direction: column;
    width: 90%;
    max-width: 500px;
    height: auto;
    }

    #s1-gif {
        width: 500px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 30px;
        height: 445px;
    }

    #placeholder-text {
        width: 100%;
    }

    #p-text-1 {
        width: calc(90% - 70px);
        padding: 32px 35px;
        margin-top: 20px;
        border-radius: 40px;
    }

    #p-title {
        width: 80%;
        text-align: center;
        margin-top: 27px;
        margin-bottom: 27px;
    }
    #p-text-2 {
        margin-bottom: 20px;
    }
  }



@media only screen and (max-width: 560px) {
    #s1-gif {
        width: 90vw;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 30px;
        height: 80vw;
    }

    #datesbox {
        flex-direction: column;
    }

    #s1-title-3 {
        margin-bottom: 12px;
    }

    #double-line {
        display: none;
    }

  }